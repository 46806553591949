import React, { useEffect, useState } from "react";
import {
  ActionButtonCustom,
  CicrlesLoading,
  CustomColorLabelledOutline,
  fetchMethod,
  getLowerCase,
  getTrimValue,
  GridContainer,
  GridItem,
} from "../../../utilities/helpers/Helpers";
import {
  CreateUpdateClientGenericSetting,
  GetGenericSetting,
} from "../../../api/AxiosInterceptors";
import { EnumOptions, inputTypesEnum } from "../../../utilities/enum";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import { styleSheet } from "../../../assets/styles/style";
import { Grid, InputLabel, TextField } from "@mui/material";
import Colors from "../../../utilities/helpers/Colors";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { errorNotification, successNotification } from "../../../utilities/toast";

const GenericSettingPage = () => {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [pageLoading, setPageLoading] = useState(false);
  const [genericSettingData, setGenericSettingData] = useState([]);
  const [parseGenericData, setParseGenericData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (section_index, input_index, value) => {
    setParseGenericData((prev) => {
      const _configSettings = [...prev];
      _configSettings[section_index].inputData[input_index].value = value;
      return _configSettings;
    });
  };

  const getGenericSetting = async () => {
    setPageLoading(true);
    try {
      const response = await GetGenericSetting();
      console.log(response);
      if (response) {
        const ParseData = JSON.parse(response?.data?.result);
        setGenericSettingData(ParseData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setPageLoading(false);
    }
  };

  const createUpdateClientGenericSetting = async () => {
    const _submitData = parseGenericData.map((section) => {
      const _section = { ...section };
      const _section_inputData = section.inputData.map((input_dt) => {
        const _input_data = { ...input_dt };
        if (!getTrimValue(input_dt.value) || input_dt.value.value === 0) {
          errorNotification(`The ${input_dt.label}: Is required to proceed`);
          return false;
        }
        if (getLowerCase(_input_data.type) === inputTypesEnum.SELECT) {
          const manipulated_data = _input_data.data.map((option) => {
            return option.value;
          });

          _input_data.data = manipulated_data;
          _input_data.value = input_dt.value.value;
        }
        return _input_data;
      });
      _section.inputData = _section_inputData;
      return _section;
    });

    const { response } = await fetchMethod(
      () => CreateUpdateClientGenericSetting(_submitData),
      setIsLoading,
      false
    );
    if (response.isSuccess) {
      successNotification("Config Settings updated successfully");
      getGenericSetting();
    } else {
      errorNotification("Something went wrong while updating Config settings");
    }
  };

  useEffect(() => {
    const _configSettings = genericSettingData?.map((section) => {
      const _section = { ...section };
      const _section_inputData = section.inputData.map((input_dt) => {
        const _input_data = { ...input_dt };
        if (getLowerCase(_input_data.type) === inputTypesEnum.SELECT) {
          const manipulated_data = _input_data.data.map((option) => {
            return { label: option, value: option };
          });

          _input_data.data = manipulated_data;
          _input_data.value = { label: input_dt.value, value: input_dt.value };
        }
        return _input_data;
      });
      _section.inputData = _section_inputData;
      return _section;
    });
    setParseGenericData(_configSettings);
  }, [genericSettingData]);

  useEffect(() => {
    getGenericSetting();
  }, []);

  return (
    <>
      {pageLoading ? (
        <CicrlesLoading />
      ) : (
        <Box sx={{}}>
          <GridContainer spacing={2} padding="20px">
            {parseGenericData.map((section, section_index) => (
              <GridItem lg={8} md={8} sm={8} key={section.key}>
                <CustomColorLabelledOutline label={section.sectionName}>
                  <GridContainer>
                    {section.inputData.map((input, input_index) => (
                      <GridItem
                        lg={6}
                        md={6}
                        sm={6}
                        key={input.key}
                        sx={{
                          paddingTop: "8px !important",
                          marginBottom: "2px !important",
                        }}
                      >
                        <InputLabel
                          required={input.required}
                          sx={styleSheet.inputLabel}
                        >
                          {input.label}
                        </InputLabel>
                        {getLowerCase(input.type) === inputTypesEnum.SELECT && (
                          <SelectComponent
                            height={40}
                            name={input.key}
                            options={input.data}
                            optionLabel={
                              EnumOptions.CONFIG_SETTING_INPUTDATA_OPTIONS.LABEL
                            }
                            optionValue={
                              EnumOptions.CONFIG_SETTING_INPUTDATA_OPTIONS.VALUE
                            }
                            value={input.value}
                            onChange={(e, val) => {
                              handleInputChange(
                                section_index,
                                input_index,
                                val
                              );
                            }}
                          />
                        )}
                        {(getLowerCase(input.type) === inputTypesEnum.TEXT ||
                          getLowerCase(input.type) ===
                            inputTypesEnum.NUMBER) && (
                          <TextField
                            type={getLowerCase(input.type)}
                            placeholder={input.value}
                            size="small"
                            fullWidth
                            variant="outlined"
                            required={input.required}
                            value={input.value}
                            onChange={(e) => {
                              const val = e.target.value;
                              handleInputChange(
                                section_index,
                                input_index,
                                val
                              );
                            }}
                          />
                        )}
                      </GridItem>
                    ))}
                  </GridContainer>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="end" marginTop="20px">
                      <ActionButtonCustom
                        label={
                          LanguageReducer?.languageType
                            ?.SETTINGS_DOCUMENT_SETTINGS_SAVE
                        }
                        background={Colors.primary}
                        loading={isLoading}
                        onClick={createUpdateClientGenericSetting}
                      />
                    </Box>
                  </Grid>
                </CustomColorLabelledOutline>
              </GridItem>
            ))}
          </GridContainer>
        </Box>
      )}
    </>
  );
};

export default GenericSettingPage;
